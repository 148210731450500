import React, { useState, useEffect } from 'react'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ReactSwitch from 'react-switch'
import axios from 'axios'
import Swal from 'sweetalert2'


const Gateway = () => {

//////////URL//////////////
const [paynow1Url, SetPayNow1Url] = useState('')
const [paynow2Url, SetPayNow2Url] = useState('')
const [upi, setUpiId] = useState('')
const [paynow3Url, SetPayNow3Url] = useState('')
const [paynow4Url, SetPayNow4Url] = useState('')
const [paynow5Url, SetPayNow5Url] = useState('')

  const [checkedRozorPayIn, setCheckedRozorPayIn] = useState(true)
  const [checkedRozorPay, setCheckedRozorPay] = useState(true)
  const [checkedHoadaPayIn, setCheckedHoadaPayIn] = useState(true)
  const [checkedHoadaPay, setCheckedHoadaPay] = useState(true)
  const [checkedMyPayIn, setCheckedMyPayIn] = useState(true)
  const [checkedUpiGateway, setUpiGateway] = useState(true)
  const [manualDeposit, setManualDeposit] = useState(true)
  const [checkedMyPay, setCheckedMyPay] = useState(true)
  const [isSubPaisaActive, setisSubPaisaActive] = useState(true)
  const [ispaytmWithdrawal, setIspaytmWithdrawal] = useState(true)
  const [RazorPayout, setRazorpayout] = useState(true)
  const [RazorDeposit, setRazorDeposit] = useState(true)
  const [RazorpayAuto, setRazorpayAuto] = useState(false)
  const [RazorPayKey, setRazorpayKey] = useState()
  const [RazorPaySecretKey, setRazorpaysecretKey] = useState()
  const [AccountName, setAccountName] = useState()
  const [decentroPayout, setdecentropayout] = useState(true)
  const [decentroDeposit, setdecentroDeposit] = useState(true)
  const [pinelabDeposit, setpinelabDeposit] = useState(true)
  const [decentroAuto, setdecentroAuto] = useState(false)
  const [settingId, setSettingId] = useState('')
  const [enable_otp, setEnableOtp] = useState()
  const [isCashFree, setIsCashFree] = useState(false)
  const [isPhonePayActive, setIsPhoneActive] = useState(false)
  const [PayNowOne, setPayNowOne] = useState(true)
  const [PayNowTwo, setPayNowTwo] = useState(true)
  const [PayNowThree, setPayNowThree] = useState(true)
  const [payNowFour, setPayNowFour] = useState(true)
  const [isPayNowFive, setPayNowFive] = useState(true)


  // images manual payment
const [payOneImage,setPayOneImage] = useState(null)
const [payTwoImage,setPayTwoImage] = useState(null)
const [payThreeImage,setPayThreeImage] = useState(null)
const [payFourImage,setPayFourImage] = useState(null)
const [payFiveImage,setPayFiveImage] = useState(null)

  // console.log(enable_otp)
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API
  const nodeMode = process.env.NODE_ENV
  if (nodeMode === 'development') {
    var baseUrl = beckendLocalApiUrl
  } else {
    baseUrl = beckendLiveApiUrl
  }

  useEffect(() => {

    const data = axios.get(baseUrl + 'gatewaysettings/data', {}).then(res => {
      console.log(res.data)
      setSettingId(res.data._id ? res.data._id : '')
      setRazorpayout(res.data.RazorPayout)
      setRazorDeposit(res.data.RazorDeposit)
      setRazorpayAuto(res.data.RazorpayAuto)
      setdecentropayout(res.data.decentroPayout)
      setdecentroDeposit(res.data.decentroDeposit)
      setdecentroAuto(res.data.decentroAuto)
      setRazorpayKey(res.data.RazorPayKey)
      setRazorpaysecretKey(res.data.RazorPaySecretKey)
      setAccountName(res.data.AccountName)
      setpinelabDeposit(res.data.pinelabDeposit)
      setEnableOtp(res.data.otp)
      setUpiId(res.data.upi)
      setIsCashFree(res.data.isCashFreeActive)
      setIsPhoneActive(res.data.isPhonePayActive)
      setCheckedRozorPayIn(res.data.RazorPayout)
      setCheckedRozorPay(res.data.RazorDeposit)
      setCheckedHoadaPayIn(res.data.isHaodaPaypayInActive)
      setCheckedHoadaPay(res.data.isHaodaPayPayOutActive)
      setCheckedMyPayIn(res.data.isMypayInActive)
      setUpiGateway(res.data.isUpiGateway)
      setCheckedMyPay(res.data.isMypayOutActive)
      setManualDeposit(res.data.manualDeposit)
      setisSubPaisaActive(res.data.isSubPaisaActive)
      setIspaytmWithdrawal(res.data.ispaytmWithdrawal)
      setPayNowOne(res.data.isPayNowOne)
      setPayNowTwo(res.data.isPayNowTwo)
      setPayNowThree(res.data.isPayNowThree)
      setPayNowFour(res.data.isPayNowFour)
      setPayNowFive(res.data.isPayNowFive)
      setPayOneImage(res.data.isPayOneImage)
      setPayTwoImage(res.data.isPayTwoImage)
      setPayThreeImage(res.data.isPayThreeImage)
      setPayFourImage(res.data.isPayFourImage)
      setPayFiveImage(res.data.isPayFiveImage)
      SetPayNow1Url(res.data.paynow1Url)
      SetPayNow2Url(res.data.paynow2Url)
      SetPayNow3Url(res.data.paynow3Url)
      SetPayNow4Url(res.data.paynow4Url)
      SetPayNow5Url(res.data.paynow5Url)
    })
  }, [])

  const handleSubmit1 = async e => {
    e.preventDefault()
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }

    const response = await axios.post(baseUrl + `gatewaysettings`, {
       checkedRozorPayIn,
      checkedRozorPay,
      checkedHoadaPayIn,
       checkedHoadaPay,
       checkedMyPayIn,
       checkedUpiGateway,
       manualDeposit,
       checkedMyPay,
       PayNowOne,
       PayNowTwo,
       PayNowThree,
       payNowFour,
       isPayNowFive,
       isSubPaisaActive,
       ispaytmWithdrawal,
      settingId,
      RazorpayAuto,
      decentroPayout,
      decentroDeposit,
      decentroAuto,
      RazorPayKey,
      RazorPaySecretKey,
      AccountName,
      pinelabDeposit,
      otp: enable_otp,
      isCashFreeActive: isCashFree,
      payOneImage,
      payTwoImage,
      payThreeImage,
      payFourImage,
      payFiveImage,
      upi,
      paynow1Url,
      paynow2Url,
      paynow3Url,
      paynow4Url,
      paynow5Url
    }, {headers})
    console.log(response.data.status)
    if (response.data.status === 'success') {
      alert('Settings submitted successfully')
    } else {
      alert('Settings Not Submitted')
    }
  }


  const handleChangeRozarpayIn = val => {
    setCheckedRozorPayIn(val)
  }
  const handleChangeRozarpay = val => {
    setCheckedRozorPay(val)
  }
  const handleChangehoadaPayIn = val => {
    setCheckedHoadaPayIn(val)
  }
  const handleChangehoadaPay = val => {
    setCheckedHoadaPay(val)
  }
  const handleChangeMyPayIn = val => {
    setCheckedMyPayIn(val)
  }
  const handleChangeUpi = val => {
    setUpiGateway(val)
  }
  const handleChangeMyPay = val => {
    setCheckedMyPay(val)
  }
  const handleManualPay = val => {
    setManualDeposit(val)
  }
  const handleChangePhonepeIn = val => {
    setisSubPaisaActive(val)
  }
  const handleChangePaytm = val => {
    setIspaytmWithdrawal(val)
  }
  const handleChangePayOne = val => {
    setPayNowOne(val)
  }
  const handleChangePayTwo = val => {
    setPayNowTwo(val)
  }
  const handleChangePayThree = val => {
    setPayNowThree(val)
  }
  const handleChangePayFour = val => {
    setPayNowFour(val)
  }
  const handleChangePayFive = val => {
    setPayNowFive(val)
  }

  const uploadImage = (type)=>{
  if(!type){
    return false;
  }
    var formdata = new FormData();
    if(type == "PayNowOne"){
      formdata.append("status", PayNowOne);
      formdata.append("image", payOneImage);
      formdata.append("settingId", settingId);
      formdata.append("paynow1Url", paynow1Url);
    }else if(type == "PayNowTwo"){
      formdata.append("status", PayNowTwo);
      formdata.append("image", payTwoImage);
      formdata.append("settingId", settingId);
      formdata.append("paynow2Url", paynow2Url);
    }else if(type == "PayNowThree"){
      formdata.append("status", PayNowThree);
      formdata.append("image", payThreeImage);
      formdata.append("settingId", settingId);
      formdata.append("paynow3Url", paynow3Url);
    }else if(type == "PayNowFour"){
      formdata.append("status", payNowFour);
      formdata.append("image", payFourImage);
      formdata.append("settingId", settingId);
      formdata.append("paynow4Url", paynow4Url);
    }else{
      formdata.append("status", isPayNowFive);
      formdata.append("image", payFiveImage);
      formdata.append("settingId", settingId);
      formdata.append("paynow5Url", paynow5Url);
    }

formdata.append("image_type", type);


var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
};

fetch(baseUrl+"gatewaysettings/change_qr", requestOptions)
  .then(response => {
    Swal.fire(
      'Success!',
      'Result Submit Successful',
      'success'
    )
    // window.location.reload(true)
  })
  .then(result => console.log(result))
  .catch(error => console.log('error', error));
  }

  return (
    <>
      <div className='card mt-5'>
        <div className='card-body  table_bg'>
          <h4 className='dashboard_heading my-3'>Payment Gateway Settings</h4>

          {/* <h4 className='my-3 dashboard_small_heading_dark'>Pay In Or Pay Out</h4> */}
           

          {/* <h5 className='dashboard_small_heading_dark my-3'>RazorPay</h5> */}

          <form
            action='gatewaysettings'
            className='form'
            onSubmit={handleSubmit1}
            method='patch'
            enctype='multipart/form-date'
          >

<div className='form-row'>
            
            
            <div className='form-group col-md-6'>
                <Card>
                  <Card.Body>
                    <h4 className='dashboard_small_heading_blue'>
                     KvmPay
                    </h4>
                    <Container>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>KvmPay In</Col>
                        <Col sm={2}>
                          {' '}
                          <ReactSwitch
                            checked={checkedMyPayIn}
                            onChange={handleChangeMyPayIn}
                            style={{ display: 'none' }}
                          />
                        </Col>
                      </Row>
    
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>
                          <button type='submit' className='btn btn-dark'>
                            submit
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </div>
            <div className='form-group col-md-6'>
                <Card>
                  <Card.Body>
                    <h4 className='dashboard_small_heading_blue'>
                     Upi Gateway
                    </h4>
                    <Container>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>Upi Gateway</Col>
                        <Col sm={2}>
                          {' '}
                          <ReactSwitch
                            checked={checkedUpiGateway}
                            onChange={handleChangeUpi}
                            style={{ display: 'none' }}
                          />
                        </Col>
                      </Row>
    
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>
                          <button type='submit' className='btn btn-dark'>
                            submit
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </div>
            <div className='form-group col-md-6'>
                <Card>
                  <Card.Body>
                    <h4 className='dashboard_small_heading_blue'>
                     Manual Deposit
                    </h4>
                    <Container>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>Manual Deposit</Col>
                        <Col sm={2}>
                          {' '}
                          <ReactSwitch
                            checked={manualDeposit}
                            onChange={handleManualPay}
                            style={{ display: 'none' }}
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>UPI</Col>
                        <Col sm={1}>
                          <input
                            type='text'
                            style={{
                              border: '1px Solid Black',
                              borderRadius: '3px'
                            }}
                            value={upi}
                            onChange={e => setUpiId(e.target.value)}
                            placeholder='Enter Upi Id'
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>
                          <button type='submit' className='btn btn-dark'>
                            submit
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </div>
              <div className='form-group col-md-6'>
                <Card>
                  <Card.Body>
                    <h4 className='dashboard_small_heading_blue'>
                      SubPaisa
                    </h4>
                    <Container>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>SubPaisa In</Col>
                        <Col sm={2}>
                          {' '}
                          <ReactSwitch
                            checked={isSubPaisaActive}
                            onChange={handleChangePhonepeIn}
                            style={{ display: 'none' }}
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>
                          <button type='submit' className='btn btn-dark'>
                            submit
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </div>
             
          
            </div>

            

            {/* <div className='form-row'>
              <div className='form-group col-md-4'>
                <button type='submit' className='btn btn-dark'>
                  submit
                </button>
              </div>
            </div> */}
          </form>
     
        
        </div>
      </div>
    </>
  )
}

export default Gateway
